import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Brand from "../../components/Brand";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import NotFoundPage from "../404";
import { client as prismicClient, getDataByUID } from "../../libs/prismic";
import { navigate } from "gatsby";

const Brands = ({ slug }) => {
  const [brandsDoc, setBrandsDoc] = useState(null);
  const [fundPartnerFooterData, setFundPartnerFooterData] = useState([]);
  const [keyMemberNames, setKeyMemberNames] = useState([]);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [blogItems, setBlogItems] = useState([]);

  useEffect(() => {
    async function fetchFooterData() {
      try {
        const fundPartnerFooterResponse = await prismicClient().getAllByType("invest");
        setFundPartnerFooterData(fundPartnerFooterResponse || []);
      } catch (error) {
        console.error("Error fetching footer data:", error);
      }
    }
    fetchFooterData();
  }, []);

  useEffect(() => {
    async function fetchBlogData() {
      try {
        const blogPostDocResponse = await prismicClient().getAllByType("blog_post");
        const pressMediaDocResponse = await prismicClient().getAllByType("press_media_post");
        const docDataValue = [...blogPostDocResponse, ...pressMediaDocResponse];

        if (brandsDoc?.data?.brand_image_title?.length > 0) {
          const brandTitle = brandsDoc.data.brand_image_title[0]?.text || "";
          const docDataMatched = docDataValue.filter((item) =>
            item?.data?.post_to_opal_fund_partner?.includes(brandTitle)
          );

          const sortedBlogData = docDataMatched.map((item) => ({
            title: item?.data?.title?.[0]?.text || "Untitled",
            date: item?.data?.date ? new Date(item.data.date) : new Date(),
            uid: item?.uid || "",
            type: item?.type || "",
          })).sort((a, b) => b.date - a.date).slice(0, 3);
          
          setBlogItems(sortedBlogData);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    }

    if (brandsDoc) {
      fetchBlogData();
    }
  }, [brandsDoc]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDataByUID("opal_fund_partners", slug);
        if (response) {
          setBrandsDoc(response);
        } else {
          console.error("Failed to fetch data.");
          setShowErrorPage(true);
        }
      } catch (error) {
        console.error("Error fetching brand data:", error);
        setShowErrorPage(true);
      }
    }

    if (slug) {
      fetchData();
    }
  }, [slug]);

  useEffect(() => {
    if (Array.isArray(brandsDoc?.data?.brand_key_members)) {
      setKeyMemberNames(brandsDoc.data.brand_key_members.map((item) => item?.text || "Unknown Member"));
    }
  }, [brandsDoc]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleNavigate = () => {
    navigate('/signup');
  };

  return brandsDoc ? (
    <Layout>
      <Brand
        cardImage='/assets/images/cityscape.png'
        image={brandsDoc?.data?.brand_image?.url || ""}
        heading1={brandsDoc?.data?.brand_heading_1?.[0]?.text || ""}
        description={brandsDoc?.data?.brand_description_1?.[0]?.text || ""}
        imageTitle={brandsDoc?.data?.brand_image_title?.[0]?.text || ""}
        amount={brandsDoc?.data?.brand_amount?.[0]?.text || ""}
        keyMembers={keyMemberNames}
        address={brandsDoc?.data?.brand_address?.[0]?.text || ""}
        blogItems={blogItems}
        className="mb-120"
      >
        <p className="oplevest"> {fundPartnerFooterData?.[0]?.data?.prefooter?.[0]?.text || ""}</p>
        <p className="sub-brand-description">
          {fundPartnerFooterData?.[0]?.data?.prefooter?.[1]?.text || ""}
        </p>
        <Button
          variant="dark"
          className="get-started-btn"
          onClick={handleNavigate}
        >
          {fundPartnerFooterData?.[0]?.data?.prefooter_button_text?.[0]?.text || "Get Started"}
        </Button>
      </Brand>
    </Layout>
  ) : showErrorPage ? (
    <NotFoundPage />
  ) : (
    <Loader />
  );
};

export default Brands;
